import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiSignup2Props extends StoryblokBlok, BoxProps {
  title?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerIconList: Array<StoryblokBlok & AnimLayerIconListProps>;
}

export function AnimMockUiSignup2({
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerIconList: animLayerIconListBlokArray,
  title,
  ...rest
}: AnimMockUiSignup2Props) {
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerIconList] = animLayerIconListBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {animLayerInfoDialog && (
        <AnimLayerInfoDialog
          marginX="phoneSpacing2"
          className={variantBackgroundColor.background_sand}
          padding="phoneSpacing2" // <- override component's default padding
          {...animLayerInfoDialog}
        />
      )}

      {animLayerIconList && (
        <AnimLayerIconList marginX="phoneSpacing2" {...animLayerIconList} />
      )}
    </AnimatedMockUiWrapper>
  );
}
