import React, { useContext } from "react";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerKeypad } from "~animations/__layers__/mock_ui_components/AnimLayerKeypad";
import { AnimLayerUiPayoutMethod } from "~animations/__layers__/mock_ui_components/AnimLayerUiPayoutMethod";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { PageQueryContext } from "~context/PageQueryContext";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerUiPayoutMethodProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiPayoutMethod";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiWithdrawalProps extends BoxProps {
  title?: string;
  amount?: number;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerPayoutMethod: Array<StoryblokBlok & AnimLayerUiPayoutMethodProps>;
}

export function AnimMockUiWithdrawal({
  title,
  amount,
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerPayoutMethod: animLayerPayoutMethodBlokArray,
  ...rest
}: AnimMockUiWithdrawalProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerPayoutMethod] = animLayerPayoutMethodBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {amount && (
        <Box marginX="auto" textAppearance="phone_h4" fontWeight="semibold">
          {formatCurrencyString({
            amount,
            lang,
          })}
        </Box>
      )}

      {animLayerPayoutMethod && (
        <AnimLayerUiPayoutMethod
          marginX="phoneSpacing2"
          {...animLayerPayoutMethod}
        />
      )}

      <AnimLayerKeypad marginX="phoneSpacing2" />

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
