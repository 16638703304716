import React from "react";

import { AnimLayerCreditCardInfo } from "~animations/__layers__/mock_ui_components/AnimLayerCreditCardInfo";
import { AnimLayerPaymentDue } from "~animations/__layers__/mock_ui_components/AnimLayerPaymentDue";
import { AnimLayerUiBalanceWithGraph } from "~animations/__layers__/mock_ui_components/AnimLayerUiBalanceWithGraph";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerPaymentDueProps } from "~animations/__layers__/mock_ui_components/AnimLayerPaymentDue";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVrp1Props extends StoryblokBlok, BoxProps {
  textWithdrawalComplete?: string;
  textSuccess?: string;
  textComplete?: string;
  title?: string;
  animLayerUiPaymentDue: Array<StoryblokBlok & AnimLayerPaymentDueProps>;
  animLayerUiBalanceWithGraph: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
}

export function AnimMockUiVrp1({
  animLayerUiPaymentDue: animLayerUiPaymentDueBlokArray,
  animLayerUiBalanceWithGraph: animLayerUiBalanceWithGraphBlokArray,
  title,
  ...rest
}: AnimMockUiVrp1Props) {
  const [animLayerUiPaymentDue] = animLayerUiPaymentDueBlokArray || [];
  const [animLayerUiBalanceWithGraph] =
    animLayerUiBalanceWithGraphBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconRight="faUserCircle" />}

      {animLayerUiBalanceWithGraph && (
        <AnimLayerUiBalanceWithGraph
          width="100%"
          maxWidth="100%"
          {...animLayerUiBalanceWithGraph}
        />
      )}

      <AnimLayerCreditCardInfo marginX="phoneSpacing2" />

      {animLayerUiPaymentDue && (
        <AnimLayerPaymentDue
          marginX="phoneSpacing2"
          {...animLayerUiPaymentDue}
        />
      )}
      <AnimLayerUiBottomNav marginTop="auto" />
    </AnimatedMockUiWrapper>
  );
}
