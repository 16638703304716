import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ImageBackground } from "~components/ImageBackground";

import { AnimLayerAuthentication } from "~animations/__layers__/mock_ui_components/AnimLayerAuthentication";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface AnimMockUiAuthenticationProps {
  title: string;
  image: StoryblokFieldMedia;
}

export function AnimMockUiAuthentication({
  title,
  image,
  ...rest
}: AnimMockUiAuthenticationProps) {
  return (
    <AnimatedMockUiWrapper
      {...rest}
      justifyContent="center"
      className={variantBackgroundColor.background_white}
    >
      <ImageBackground
        paddingX="phoneSpacing5"
        isolation="isolate"
        image={image}
      />
      <AnimLayerAuthentication title={title} />
    </AnimatedMockUiWrapper>
  );
}
