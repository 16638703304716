import React from "react";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerInfoDialog } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVrp2Props extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerInfoDialog: Array<StoryblokBlok & AnimLayerInfoDialogProps>;
  animLayerPaymentAmountOptions: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerPaymentDate: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerPaymentMethods: Array<StoryblokBlok & AnimLayerIconListProps>;
  textComplete?: string;
  textSuccess?: string;
  textWithdrawalComplete?: string;
  title?: string;
}

export function AnimMockUiVrp2({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerInfoDialog: animLayerInfoDialogBlokArray,
  animLayerPaymentAmountOptions: animLayerPaymentAmountOptionsBlokArray,
  animLayerPaymentDate: animLayerPaymentDateBlokArray,
  animLayerPaymentMethods: animLayerPaymentMethodsBlokArray,
  title,
  ...rest
}: AnimMockUiVrp2Props) {
  /** @todo: Fix this unreadable mess */
  const [animLayerInfoDialog] = animLayerInfoDialogBlokArray || [];
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerPaymentMethods] = animLayerPaymentMethodsBlokArray || [];
  const [animLayerPaymentAmountOptions] =
    animLayerPaymentAmountOptionsBlokArray || [];
  const [animLayerPaymentDate] = animLayerPaymentDateBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav iconLeft="faChevronLeft" title={title} />}

      {animLayerInfoDialog && (
        <AnimLayerInfoDialog
          isRadioButtonVisible
          marginX="phoneSpacing2"
          {...animLayerInfoDialog}
        />
      )}

      {animLayerPaymentMethods && (
        <AnimLayerIconList
          marginX="phoneSpacing2"
          {...animLayerPaymentMethods}
        />
      )}

      {animLayerPaymentAmountOptions && (
        <AnimLayerIconList
          marginX="phoneSpacing2"
          {...animLayerPaymentAmountOptions}
        />
      )}

      {animLayerPaymentDate && (
        <AnimLayerIconList marginX="phoneSpacing2" {...animLayerPaymentDate} />
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
