import React from "react";

import { AnimLayerEcommerceItems } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import { AnimLayerEcommerceOrderDetails } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiEcommerce1Props
  extends StoryblokBlok,
    Omit<BoxProps, "lang"> {
  title?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
}

export function AnimMockUiEcommerce1({
  animLayerEcommerceItems: animLayerEcommerceItemsBlokArray,
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerEcommerceOrderDetails: animLayerEcommerceOrderDetailsBlokArray,
  title,
  ...rest
}: AnimMockUiEcommerce1Props) {
  const [animLayerEcommerceItems] = animLayerEcommerceItemsBlokArray || [];
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerEcommerceOrderDetails] =
    animLayerEcommerceOrderDetailsBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {animLayerEcommerceItems && (
        <AnimLayerEcommerceItems
          marginX="phoneSpacing2"
          {...animLayerEcommerceItems}
        />
      )}

      {/* <HorizontalDivider data-motion={ANIMATED.secondary} marginX="spacing2" /> */}

      {animLayerEcommerceOrderDetails && (
        <AnimLayerEcommerceOrderDetails
          marginX="phoneSpacing2"
          {...animLayerEcommerceOrderDetails}
        />
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
