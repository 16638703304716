import React, { useContext } from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerIconList } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import { AnimLayerTrueLayerConnection } from "~animations/__layers__/mock_ui_components/AnimLayerTrueLayerConnection";
import { AnimLayerUiMockCollapsible } from "~animations/__layers__/mock_ui_components/AnimLayerUiMockCollapsible";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import { PageQueryContext } from "~context/PageQueryContext";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerUiMockCollapsibleProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiMockCollapsible";
import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimMockUiConsentProps extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  textComplete?: string;
  description?: string;
  textSuccess?: string;
  textWithdrawalComplete?: string;
  title?: string;
  amount?: string;
  amountTitle?: string;
  bankIcon?: StoryblokFieldMedia;
  disclaimer?: string;
  animLayerIconList: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerMockCollapsible: Array<
    StoryblokBlok & AnimLayerUiMockCollapsibleProps
  >;
}

export function AnimMockUiConsent({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerIconList: animLayerIconListBlokArray,
  animLayerMockCollapsible: animLayerMockCollapsibleBlokArray,
  title,
  amount,
  amountTitle,
  bankIcon,
  description,
  disclaimer,
  ...rest
}: AnimMockUiConsentProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerIconList] = animLayerIconListBlokArray || [];
  const [animLayerMockCollapsible] = animLayerMockCollapsibleBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav iconLeft="faChevronLeft" title={title} />}

      {amountTitle && (
        <Box marginX="phoneSpacing2">
          {amountTitle && (
            <Box
              textAlign="center"
              textAppearance="phone_text_sm"
              fontWeight="semibold"
            >
              {amountTitle}
            </Box>
          )}

          {amount && (
            <Box textAlign="center" textAppearance="phone_h4">
              {formatCurrencyString({
                amount: parseFloat(amount),
                lang,
              })}
            </Box>
          )}
        </Box>
      )}

      {description && (
        <Box
          marginX="phoneSpacing2"
          textAlign="center"
          textAppearance="phone_text_sm"
          color="text_lowContrast"
        >
          {description}
        </Box>
      )}

      {bankIcon && <AnimLayerTrueLayerConnection image={bankIcon} />}

      {animLayerMockCollapsible && (
        <AnimLayerUiMockCollapsible
          marginX="phoneSpacing2"
          {...animLayerMockCollapsible}
        />
      )}

      {animLayerIconList && (
        <AnimLayerIconList
          marginX="phoneSpacing2"
          padding="phoneSpacing1"
          borderRadius="sm"
          isArrowVisible={false}
          className={variantBackgroundColor.background_sand}
          {...animLayerIconList}
        />
      )}

      {disclaimer && (
        <Box
          marginTop="auto"
          marginX="phoneSpacing2"
          __fontSize={10}
          color="text_lowContrast"
        >
          {disclaimer}
        </Box>
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginBottom="phoneSpacing4"
          size="md"
          marginX="phoneSpacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
